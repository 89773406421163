import { AutoComplete, Shell } from '@components/AutoComplete';
import { CarrierInfoSidebarV2Fragment } from '@generated/fragments/carrierInfoSidebarV2';
import { useSearchCarriersCarrierInfoSidebarV2LazyQuery } from '@generated/queries/searchCarriersCarrierInfoSidebarV2';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { Actions } from 'downshift';
import { Component, FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { renderSideBarResults } from '../common';
import { SIDEBAR_SEARCH_STYLE } from '../style';

const CarrierSearchBase: FC<RouteComponentProps> = ({ history: { push } }) => {
  const carrierSelectHandler = (
    item: Shell<CarrierInfoSidebarV2Fragment> | null,
    helpers?: Actions<Shell<CarrierInfoSidebarV2Fragment>>
  ): void => {
    if (item) {
      helpers?.clearSelection();
      helpers?.closeMenu();

      push(`/carriers/${item.id}/capacity-manager`);
    }
  };
  const [searchCarriersV2, { loading: loadingV2, data: dataV2 }] =
    useSearchCarriersCarrierInfoSidebarV2LazyQuery();
  const data = dataV2?.carriersNullableV2;

  const debouncedSearchCarriers = useDebouncedFn(searchCarriersV2, 500, []);

  const carriers = data?.edges ?? [];
  const items = carriers.map(({ node }) => ({
    value: node,
    label: node.name,
    id: node.id,
  }));

  return (
    <AutoComplete<CarrierInfoSidebarV2Fragment>
      onInputValueChange={(inputValue): void => {
        debouncedSearchCarriers({
          variables: { filter: { text: inputValue }, first: 15 },
        });
      }}
      onChange={carrierSelectHandler}
      loading={loadingV2}
      items={items}
      DEPRECATEDrenderItem={renderSideBarResults<CarrierInfoSidebarV2Fragment>()}
      renderDropdownInPopper
      showSearchIcon
      css={{ height: '100%' }}
      inputProps={{
        placeholder: 'Search',
        'data-testid': 'sidebar-carrier-search-input',
        'aria-label': 'Search Carriers',
        css: SIDEBAR_SEARCH_STYLE,
      }}
    />
  );
};

export const CarrierSearch: typeof Component = withRouter(CarrierSearchBase);
